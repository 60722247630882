import React, { Component } from 'react';
import emailjs from '@emailjs/browser';

    
    class Contact extends Component {
        constructor(props){
           super(props);
      
           this.state = {
               fields: {},
               errors: {}
           }
        }
    
        handleValidation(){
            let fields = this.state.fields;
            let errors = {};
            let formIsValid = true;

            //Name
            if(!fields["name"]){
               formIsValid = false;
               errors["name"] = "Required";
            }
      
            if(typeof fields["name"] !== "undefined"){
                if(fields["name"].length > 30 ){
                   formIsValid = false;
                   errors["name"] = "More more than 30 charracters";
                }        
             }
           
       
            //Email
            if(!fields["email"]){
               formIsValid = false;
               errors["email"] = "Required";
            }
      
            if(typeof fields["email"] !== "undefined"){
               let lastAtPos = fields["email"].lastIndexOf('@');
               let lastDotPos = fields["email"].lastIndexOf('.');

               if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                  formIsValid = false;
                  errors["email"] = "Email is not valid";
                }
           }  

           if(typeof fields["email"] !== "undefined"){
            if(fields["email"].length > 30 ){
               formIsValid = false;
               errors["email"] = "More more than 30 charracters";
            }        
         }
             //Subject 
             if(!fields["subject"]){
                formIsValid = false;
                errors["subject"] = "Required";
             }  

             if(typeof fields["subject"] !== "undefined"){
                if(fields["subject"].length > 30 ){
                   formIsValid = false;
                   errors["subject"] = "More more than 30 charracters";
                }        
             }
             

              //Message 
              if(!fields["message"]){
                formIsValid = false;
                errors["message"] = "Required";
             }  

             if(typeof fields["message"] !== "undefined"){
                if(fields["message"].length > 800 ){
                   formIsValid = false;
                   errors["message"] = "More more than 800 charracters";
                }        
             }
        
            
           this.setState({errors: errors});
           return formIsValid;
       }
        
       contactSubmit(e){
            e.preventDefault();

            
           
            if(this.handleValidation()){
                emailjs.sendForm(process.env.React_App_YOUR_SERVICE_ID, process.env.React_App_YOUR_TEMPLATE_ID, e.target, process.env.React_App_YOUR_USER_ID)
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
                alert("Form submitted");
                let fields = this.state.fields;
                fields["name"] = ""
                fields["email"] = ""
                fields["subject"] = ""
                fields["message"] = ""
               
            }
                else{
               alert("Form has errors.")
            }
      
        }
    
        handleChange(field, e){         
            let fields = this.state.fields;
            fields[field] = e.target.value;        
            this.setState({fields});
        }
    
        render(){
            return (

            <section id="contact">

                     <div className="row">
                     <div className="two columns header-col">

                        <h1><span>Get In Touch.</span></h1>

                     </div>
                     </div>
                 <div className="ten columns flex-container"></div>

                <div>           
                   <form name="contactform" className="contactform" onSubmit= {this.contactSubmit.bind(this)}>
                        <div className="col-md-6">
                         
                          <fieldset  className="Form1">
                          <label htmlFor="contactName">Name<span className="required">*</span></label>
                             <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                             <input name="name" type="text" size="30" placeholder="" maxlength ="30"  onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"]}/>
                              <br/>
                            <label htmlFor="contactEmail">Email <span className="required">*</span></label>
                            <span style={{color: "red"}}>{this.state.errors["email"]}</span>
                             <input name="email" type="email" size="30" placeholder="" maxlength ="30" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}/>
                              <br/>
                              <label htmlFor="contactSubject">Subject<span className="required">*</span></label>
                              <span style={{color: "red"}}>{this.state.errors["subject"]}</span>
                              <input name="subject" type="text" size="30" placeholder="" maxlength ="30" onChange={this.handleChange.bind(this, "subject")} value={this.state.fields["subject"]}/>
                               <br/>
                               <label htmlFor="contactMessage">Message<span className="required">*</span></label>
                              <span style={{color: "red"}}>{this.state.errors["message"]}</span>
                              <textarea name="message" type="text" size="30" placeholder="" maxlength ="800" cols="50" rows="15"onChange={this.handleChange.bind(this, "message")} value={this.state.fields["message"]}></textarea>
                               <br/>
                               <label htmlFor="contactMessage"></label>
                             <input type="submit" className="button1"  value="Send Message" ></input>
                         </fieldset>
                         
                      </div>
                    </form>
                </div>
                
           
            </section>
          )
        }
    }


export default Contact
